<template>
  <div class="search-list-page">
    <Breadcrumbs />
    <div class="container">
      <div class="row">
        <SearchProductFilter class="col-lg-3" />
        <ProductsListingSearch class="col-lg-9"  />
      </div>
    </div>
    <CompareButton></CompareButton>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import SearchProductFilter from "@/base/core/components/search/SearchProductFilter";
import ProductsListingSearch from "@/base/core/components/search/ProductsListingSearch";
import CompareButton from "@/base/core/components/common/CompareButton.vue";
import config from "@config";
import { extractFilters } from "@storefront/core/router/helpers/cleanPath.js";

export default {
  name: "Search",
  components: {
    Breadcrumbs,
    SearchProductFilter,
    ProductsListingSearch,
    CompareButton,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("search"),
        },
        {
          name: "keywords",
          content: this.$t("search") + ", " + this.searchWord,
        },
        {
          name: "description",
          content:
            this.$t("search") + (this.searchWord ? ": " + this.searchWord : ""),
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("search"),
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  watch: {
    // searchWord() {
    //   if (this.searchWord != "") {
    //     this.$store.dispatch("search/load", { search: this.searchWord });
    //     const bcrumb = { current: this.$route.query.q, routes: [] };
    //     this.$store.commit("breadcrumbs/set", bcrumb);
    //   }
    // },
  },
  async mounted() {
    if (this.searchWord != "") {
      const filter = extractFilters(this.$route);
      await this.$store.dispatch("search/load", {
        search: this.searchWord,
        filter: filter,
      });
    }
    const bcrumb = { current: this.$route.query.q, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  destroyed() {
    this.$store.commit("search/setLoadProducts", false);
    Logger.debug(
      "DESTROYED",
      "Search.vue",
      this.$store.getters["search/getLoadProducts"]
    )();
  },
};
</script>

<style lang="scss" scoped>
.search-list-page {
  margin-bottom: 80px;
}
</style>
